import { ApiServiceWithSettings, Audience, Authentication } from '../../common'
import { AxiosPromise } from 'axios'
import {
  Catalog,
  Categories,
  Pagination,
  Products,
  Suggestions
} from '@one/types'
import qs from 'qs'

export class BackofficeCatalogApi extends ApiServiceWithSettings {
  getProductBySlug(slug: string): AxiosPromise<Products.Product> {
    return this.get('/products/by-slug', {
      params: {
        slug
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getProduct(id: string): AxiosPromise<Products.Product> {
    return this.get('/products/by-sku', {
      params: {
        id
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getProductByIndexOrId(
    idOrIndex: string
  ): AxiosPromise<Array<Products.Product>> {
    return this.get('/products/list-by-id-or-index', {
      params: {
        idOrIndex
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getProductByManufacturerIndex(
    manufacturerIndex: string
  ): AxiosPromise<Array<Products.Product>> {
    return this.get('/products/list-by-manufacturer-index', {
      params: {
        manufacturerIndex
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getProducts(
    pageNumber: number,
    pageSize: number,
    category: string,
    filtersQuery: Array<string>,
    price: string,
    q: string,
    sortCriteria: Products.SortingOptionCode
  ): AxiosPromise<Products.Backoffice.Responses.ProductsResult> {
    return this.get('/products/search/items', {
      params: {
        pageNumber,
        pageSize,
        price,
        q,
        sortCriteria,
        filtersQuery,
        category
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
  }

  getFilters(page?: Products.Requests.FiltersPagination): AxiosPromise {
    return this.get('/products/search/filters', {
      params: {
        category: page && page.category ? page.category : undefined,
        filtersQuery:
          page && page.filtersQuery && page.filtersQuery.length > 0
            ? page.filtersQuery
            : undefined,
        q: page && page.q ? page.q : '*',
        price: page && page.price ? page.price : undefined
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
  }

  getSuggestions(
    query: string
  ): AxiosPromise<Suggestions.Responses.Suggestion> {
    return this.get('/suggestions', {
      params: {
        userQuery: query
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getSynonyms(): AxiosPromise<Array<Catalog.Backoffice.SynonymDto>> {
    return this.get('/synonyms', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        ttl: 60 * 100,
        tags: ['synonyms']
      }
    })
  }

  createSynonym(synonym: Catalog.Backoffice.SynonymDto): AxiosPromise<void> {
    this.invalidate(['synonyms'])
    return this.post('/synonyms', synonym, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  deleteSynonym(synonym: Catalog.Backoffice.SynonymDto): AxiosPromise<void> {
    this.invalidate(['synonyms'])
    return this.delete('/synonyms', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      data: synonym
    })
  }

  getSynonymByName(
    name: string
  ): AxiosPromise<Array<Catalog.Backoffice.SynonymDto>> {
    return this.get(`/synonyms/${name}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getAllCharacteristics(
    nameFilter: string | null,
    pageNumber: number,
    pageSize: number
  ): AxiosPromise<Catalog.Backoffice.Responses.CharacteristicsPager> {
    return this.get('/characteristics', {
      params: {
        nameFilter: nameFilter || null,
        pageNumber,
        pageSize
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        tags: ['characteristics']
      }
    })
  }

  getAllCharacteristicValues(
    code: string | null,
    pageNumber: number,
    pageSize: number,
    valueFilter: string | null
  ): AxiosPromise<Catalog.Backoffice.Responses.CharacteristicValuesPager> {
    return this.get(`/characteristics/${code}/values`, {
      params: {
        pageNumber,
        pageSize,
        valueFilter: valueFilter || null
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        tags: ['characteristics']
      }
    })
  }

  updateCharacteristic(
    body: Catalog.Backoffice.Requests.CharacteristicNameBody,
    code: string | null
  ): AxiosPromise<void> {
    this.invalidate(['characteristics'])
    return this.post(`/characteristics/${code}`, body, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updateCharacteristicValue(
    body: Catalog.Backoffice.Requests.CharacteristicValueBody,
    code: string | null,
    valueCode: string | null
  ): AxiosPromise<void> {
    this.invalidate(['characteristics'])
    return this.post(`/characteristics/${code}/values/${valueCode}`, body, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getCategories(): AxiosPromise<Array<Categories.Category>> {
    return this.get('/categories/list', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getProductSegments(
    pageNumber: number,
    pageSize: number
  ): AxiosPromise<Array<Catalog.Backoffice.Responses.SegmentsPager>> {
    return this.get('/product-segments', {
      params: {
        pageNumber,
        pageSize
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createProductSegment(
    assignment: Catalog.Backoffice.Segment
  ): AxiosPromise<void> {
    return this.post('/product-segments', assignment, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getProductSegment(
    label: string
  ): AxiosPromise<Array<Catalog.Backoffice.Segment>> {
    return this.get(`/product-segments/by-label/${label}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updateProductSegment(
    label: string,
    assignment: Catalog.Backoffice.SegmentsList
  ): AxiosPromise<void> {
    return this.put(`/product-segments/by-label/${label}`, assignment, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  deleteProductSegment(label: string): AxiosPromise<void> {
    return this.delete(`/product-segments/by-label/${label}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getGlobalProductSegment(): AxiosPromise<
    Array<Catalog.Backoffice.SegmentsLabel>
  > {
    return this.get(`/product-segments/public-products-label/`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  setGlobalProductSegment(label: string): AxiosPromise<void> {
    return this.put(
      `/product-segments/public-products-label/`,
      { label },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  getLabelsList(): AxiosPromise<Array<string>> {
    return this.get(`/labels/list/`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        tags: ['labels']
      }
    })
  }

  getDynamicParametersProductSeo(): AxiosPromise<
    Catalog.Backoffice.Seo.ProductSeoDefaultsDynamicParameters
  > {
    return this.get(`/seo/product/default/variables`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getMeasurementUnits(): AxiosPromise<
    Array<Catalog.Backoffice.Responses.MeasurementUnit>
  > {
    return this.get(`/dictionaries/measurement-units`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createMeasurementUnit(
    payload: Catalog.Backoffice.Requests.CreateMeasurementUnit
  ): AxiosPromise<void> {
    return this.post('/dictionaries/measurement-units', payload, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updateMeasurementUnit(
    measurementUnitId: string,
    payload: Catalog.Backoffice.Requests.CreateMeasurementUnit
  ): AxiosPromise<void> {
    return this.put(
      `/dictionaries/measurement-units/${measurementUnitId}`,
      payload,
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  deleteMeasurementUnit(measurementUnitId: string): AxiosPromise<void> {
    return this.delete(`/dictionaries/measurement-units/${measurementUnitId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getVatRates({
    pageNumber,
    pageSize
  }: Pagination.Page): AxiosPromise<
    Pagination.Pager<Catalog.Backoffice.Responses.VatRate>
  > {
    return this.get('/dictionaries/vat-rates', {
      params: {
        pageNumber,
        pageSize
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createVatRate(
    payload: Catalog.Backoffice.Requests.CreateVatRate
  ): AxiosPromise<void> {
    return this.post('/dictionaries/vat-rates', payload, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updateVatRate(
    vatRateId: string,
    payload: Catalog.Backoffice.Requests.CreateVatRate
  ): AxiosPromise<void> {
    return this.put(`/dictionaries/vat-rates/${vatRateId}`, payload, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  deleteVatRate(vatRateId: string): AxiosPromise<void> {
    return this.delete(`/dictionaries/vat-rates/${vatRateId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getManufacturers({
    pageNumber,
    pageSize,
    search
  }: {
    pageNumber: number
    pageSize: number
    search: string
  }): AxiosPromise<
    Pagination.Pager<Catalog.Backoffice.Responses.Manufacturer>
  > {
    return this.get('/dictionaries/manufacturers', {
      params: {
        pageNumber,
        pageSize,
        search
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createManufacturers(
    payload: Catalog.Backoffice.Requests.CreateManufacturer
  ): AxiosPromise<void> {
    return this.post('/dictionaries/manufacturers', payload, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updateManufacturers(
    manufacturerId: string,
    payload: Catalog.Backoffice.Requests.CreateManufacturer
  ): AxiosPromise<void> {
    return this.put(`/dictionaries/manufacturers/${manufacturerId}`, payload, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  deleteManufacturers(manufacturerId: string): AxiosPromise<void> {
    return this.delete(`/dictionaries/manufacturers/${manufacturerId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createNewDraftProduct(
    payload: Catalog.Backoffice.Requests.CreateDraftProduct
  ): AxiosPromise<Catalog.Backoffice.Responses.DraftProductCreateResult> {
    return this.post(`/draft-products`, payload, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  sendProductActivation(productId: string): AxiosPromise<void> {
    return this.post(
      '/products/send-for-activation',
      {},
      {
        params: {
          productId
        },
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  getProductsByIdentity(
    products: Catalog.Backoffice.ProductIdentityRequest
  ): AxiosPromise<Catalog.Backoffice.ProductIdentityRestView> {
    return this.post(
      '/products/by-identity',
      {
        ...products
      },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  getFiltersGeneric(
    code: string,
    categoryId?: string | null,
    filtersQuery?: string[] | null,
    price?: string | null,
    q?: string | null
  ): AxiosPromise<void> {
    return this.get(`/products/search/filters/generic/${code}`, {
      params: {
        categoryId: categoryId || null,
        filtersQuery: filtersQuery || null,
        price: price || null,
        q: q || null
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
}
