import { ApiServiceWithSettings, Audience, Authentication } from '../../common'
import { CMS, Pagination } from '@one/types'
import { AxiosPromise } from 'axios'
import qs from 'qs'
import Pages = CMS.Responses.Pages
import Page = CMS.Page
import PageContent = CMS.PageContent
import ListingBannersListDto = CMS.Responses.ListingBannersListDto
import ListingBannerDto = CMS.Responses.ListingBannerDto
import CreateListingBanner = CMS.CreateListingBanner
import ListingBanner = CMS.ListingBanner
import ArticlesPage = CMS.Requests.ArticlesPage
import ArticleList = CMS.Responses.ArticleList
import ArticlePublication = CMS.Responses.ArticlePublication
import UpdateArticle = CMS.Requests.UpdateArticle
import CreateArticleVersion = CMS.Requests.CreateArticleVersion
import Article = CMS.Articles.Article
import ArticleTag = CMS.Articles.Tags.ArticleTag
import CreateArticleTag = CMS.Requests.CreateArticleTag
import VersionInfo = CMS.VersionInfo
import GridTemplateUpdateDto = CMS.Requests.GridTemplateUpdateDto
import GridTemplatePublicationDto = CMS.Responses.GridTemplatePublicationDto
import GridElement = CMS.Grid.GridElement
import GridTemplateDto = CMS.Grid.GridTemplateDto
import GridTemplatePublicationListsDto = CMS.Responses.GridTemplatePublicationListsDto
import GridTemplateCreateDto = CMS.Requests.GridTemplateCreateDto
import PublicationListStaticPageDto = CMS.Responses.PublicationListStaticPageDto
import PublicationStaticPageDto = CMS.Responses.PublicationStaticPageDto
import UpdateStaticPageDto = CMS.Requests.UpdateStaticPageDto
import CreateContentStaticPageDto = CMS.Requests.CreateContentStaticPageDto
import ContentStaticPageDto = CMS.StaticPages.ContentStaticPageDto
import CreateStaticPageDto = CMS.Requests.CreateStaticPageDto
import TranslationsDto = CMS.Responses.TranslationsDto
import TranslationsResponse = CMS.Responses.TranslationsResponse
import SimpleDashboardDto = CMS.Responses.SimpleDashboardDto
import DashboardDto = CMS.Responses.DashboardDto
import { pickBy } from 'lodash-es'

export class BackofficeCmsApi extends ApiServiceWithSettings {
  addMainPageConfiguration(
    data: CMS.Responses.MainCmsEntity
  ): AxiosPromise<void> {
    return this.post('/main-cms', data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  publishMainPageConfiguration(version: number): AxiosPromise<void> {
    return this.post(
      `/main-cms/publish/${version}`,
      {},
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  unpublishMainPageConfiguration(): AxiosPromise<void> {
    return this.post(
      `/main-cms/unpublish`,
      {},
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  getMainPageCms(): AxiosPromise<CMS.Responses.MainCmsPublication> {
    return this.get(`/main-cms`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getMainPageCmsVersion(version: number): AxiosPromise<CMS.Responses.MainCms> {
    return this.get(`/main-cms/${version}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getMainPageConfiguration(): AxiosPromise<CMS.Responses.MainCms> {
    return this.get(`/main-cms/latest`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getPages(): AxiosPromise<Pages> {
    return this.get('/page', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getPageContents(pageId: string): AxiosPromise<CMS.PageSingleContent> {
    return this.get(`/page/${pageId}/content`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  addNewPage(page: Page): AxiosPromise<Page> {
    return this.post('/page', page, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  addPageContent(id: number, page: PageContent): AxiosPromise<VersionInfo> {
    return this.post(`/page/${id}/content`, page, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  publishPageContent(id: number, version: number): AxiosPromise<void> {
    return this.post(
      `/page/${id}/content/version/${version}/publish`,
      {},
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  unpublishPageContent(id: number): AxiosPromise<void> {
    return this.post(
      `/page/${id}/content/unpublish`,
      {},
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  deletePage(pageId: string): AxiosPromise<void> {
    return this.delete(`/page/${pageId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getBannersList(): AxiosPromise<ListingBannersListDto> {
    return this.get('/banners/get', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getBanner(id: string): AxiosPromise<ListingBannerDto> {
    return this.get(`/banners/get/${id}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  addBanner(banner: CreateListingBanner): AxiosPromise<CreateListingBanner> {
    return this.post('/banners/save', banner, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updateBanner(banner: ListingBanner): AxiosPromise<ListingBannerDto> {
    return this.put(`/banners/update`, banner, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  deleteBanner(id: string): AxiosPromise<void> {
    return this.delete(`/banners/delete/${id}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getAssetsGroups(): AxiosPromise<CMS.Upload.AssetGroupDto> {
    return this.get('/asset-groups', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        ttl: 60 * 1000,
        tags: ['assetGroups']
      }
    })
  }

  createAssetGroup(
    data: CMS.Upload.CreateAssetGroupDto
  ): AxiosPromise<CMS.Upload.AssetGroupDto> {
    this.invalidate(['assetGroups'])
    return this.post('/asset-groups', data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  deleteAssetGroups(ids: Array<string>): AxiosPromise<void> {
    this.invalidate(['assetGroups'])
    return this.delete(
      `/asset-groups?${ids.map(item => `ids=${item}`).join('&')}`,
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE,
        data: ids
      }
    )
  }

  beginUpload(
    data: CMS.Upload.BeginUploadDto
  ): AxiosPromise<CMS.Upload.UploadDto> {
    return this.post('/assets/begin-upload', data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  uploadImageCms(
    uploadUrl: any,
    formData: any,
    fileType: string
  ): AxiosPromise<void> {
    return this.put(uploadUrl, formData, {
      rawUrl: true,
      injectToken: false,
      injectTenant: false,
      headers: {
        'Content-Type': fileType
      }
    })
  }

  finishUpload(data: CMS.Upload.UploadDto): AxiosPromise<CMS.Upload.UploadDto> {
    return this.post('/assets/finish-upload', data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getAssets(assetGroupId: string): AxiosPromise<Array<CMS.Upload.AssetDto>> {
    return this.get('/assets', {
      params: {
        assetGroupId
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getAsset(id: string): AxiosPromise<CMS.Upload.AssetDto> {
    return this.get(`/assets/${id}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  deleteAssets(ids: Array<string>): AxiosPromise<void> {
    return this.delete(`/assets?${ids.map(item => `ids=${item}`).join('&')}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      data: ids
    })
  }

  fetchSettings(): AxiosPromise<Array<CMS.Responses.SettingDto>> {
    return this.get('/settings', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  setSettingsKey(key: string, value: any): AxiosPromise<void> {
    return this.put(`/settings/${key}`, value, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  getGlobalClientSeo(): AxiosPromise<CMS.Responses.SeoGlobalDto> {
    return this.get('/seo', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getGlobalClientSeoUpdate(
    data: CMS.Responses.SeoGlobalDto
  ): AxiosPromise<void> {
    return this.put('/seo', data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  addGlobalStyle(data: CMS.Requests.CreateGlobalStyle): AxiosPromise<void> {
    return this.post('/global-style', data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  addGlobalScript(data: CMS.GlobalScripts): AxiosPromise<void> {
    return this.post('/global-script', data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  addGlobalTags(data: CMS.Requests.CreateGlobalTags): AxiosPromise<void> {
    return this.post('/global-tags', data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  fetchPlans(
    publicationId?: string
  ): AxiosPromise<Array<CMS.Publications.Plan>> {
    return this.get('/publications/plan', {
      params: {
        publicationId
      },
      cache: {
        tags: ['plans']
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  cancelPlan(planId: string): AxiosPromise<void> {
    this.invalidate(['plans'])
    return this.delete(`/publications/plan/${planId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  planPublication(
    newPlan: CMS.Requests.CreatePublicationPlan
  ): AxiosPromise<CMS.Publications.Plan> {
    this.invalidate(['plans'])
    return this.post('/publications/plan/publish', newPlan, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  planUnpublication(
    newPlan: CMS.Requests.CreatePublicationPlan
  ): AxiosPromise<CMS.Publications.Plan> {
    this.invalidate(['plans'])
    return this.post('/publications/plan/unpublish', newPlan, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  publish(publicationId: string, version: string): AxiosPromise<void> {
    this.invalidate(['plans', 'publishable'])
    return this.post(
      `/publications/publish/${publicationId}/${version}`,
      {},
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  unpublish(publicationId: string, version: string): AxiosPromise<void> {
    this.invalidate(['plans', 'publishable'])
    return this.post(
      `/publications/unpublish/${publicationId}/${version}`,
      {},
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  createArticle(
    payload: CMS.Requests.CreateArticle
  ): AxiosPromise<ArticlePublication> {
    this.invalidate(['articles'])
    return this.post('/articles/new', payload, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  fetchArticles({
    pageNumber,
    pageSize,
    tag,
    search
  }: ArticlesPage): AxiosPromise<Pagination.Pager<ArticleList>> {
    this.invalidate(['articles', 'publishable'])
    return this.get('/articles/', {
      params: {
        pageNumber,
        pageSize,
        tag,
        search
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
  }

  getArticle(id: string): AxiosPromise<ArticlePublication> {
    return this.get(`/articles/by-id/${id}`, {
      cache: {
        tags: ['articles', 'publishable']
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updateArticle(
    id: string,
    payload: UpdateArticle
  ): AxiosPromise<ArticlePublication> {
    this.invalidate(['articles', 'publishable'])
    return this.put(`/articles/by-id/${id}`, payload, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  deleteArticle(id: string): AxiosPromise<void> {
    this.invalidate(['articles', 'publishable'])
    return this.delete(`/articles/by-id/${id}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createNewArticleVersion(
    id: string,
    payload: CreateArticleVersion
  ): AxiosPromise<Article> {
    this.invalidate(['articles', 'publishable'])
    return this.post(`/articles/by-id/${id}/version`, payload, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getArticleTags(): AxiosPromise<ArticleTag> {
    return this.get(`/article-tags`, {
      cache: {
        tags: ['article-tags']
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createArticleTag(payload: CreateArticleTag): AxiosPromise<ArticleTag> {
    this.invalidate(['article-tags'])
    return this.post('/article-tags/new', payload, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  deleteArticleTag(id: string): AxiosPromise<void> {
    this.invalidate(['article-tags'])
    return this.delete(`/article-tags/by-id/${id}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  uploadIconsPack(file: File, onUploadProgressCb: any): AxiosPromise<void> {
    // FIXME: To nie są product-icons...
    return this.put('/product-icons', file, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      onUploadProgress: onUploadProgressCb,
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    })
  }

  getGridById(id: string): AxiosPromise<GridTemplatePublicationDto> {
    return this.get<GridTemplatePublicationDto>(`/grid/by-id/${id}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  updateGrid(id: string, update: GridTemplateUpdateDto): AxiosPromise<void> {
    return this.put(`/grid/by-id/${id}`, update, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  deleteGridById(id: string): AxiosPromise<void> {
    return this.delete(`/grid/by-id/${id}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  addGridVersion(
    id: string,
    create: GridElement
  ): AxiosPromise<GridTemplateDto> {
    return this.post<GridTemplateDto>(`/grid/by-id/${id}/newVersion`, create, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  getGridList({
    pageNumber,
    pageSize
  }: Pagination.Page): AxiosPromise<
    Pagination.Pager<GridTemplatePublicationListsDto>
  > {
    return this.get(`/grid/list`, {
      params: {
        pageNumber,
        pageSize
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  createGrid(
    create: GridTemplateCreateDto
  ): AxiosPromise<GridTemplatePublicationDto> {
    return this.post<GridTemplatePublicationDto>('/grid/new', create, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  getStaticPages({
    pageNumber,
    pageSize
  }: Pagination.Page): AxiosPromise<
    Pagination.Pager<PublicationListStaticPageDto>
  > {
    return this.get('/static-page/', {
      params: {
        pageNumber,
        pageSize
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  getStaticPageById(id: string): AxiosPromise<PublicationStaticPageDto> {
    return this.get(`/static-page/by-id/${id}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  updateStaticPageById(
    id: string,
    update: UpdateStaticPageDto
  ): AxiosPromise<PublicationStaticPageDto> {
    return this.put(`/static-page/by-id/${id}`, update, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  deleteStaticPageById(id: string): AxiosPromise<void> {
    return this.delete(`/static-page/by-id/${id}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  createNewStaticPageVersion(
    id: string,
    create: CreateContentStaticPageDto
  ): AxiosPromise<ContentStaticPageDto> {
    return this.post(`/static-page/by-id/${id}/version`, create, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  createStaticPage(
    create: CreateStaticPageDto
  ): AxiosPromise<PublicationStaticPageDto> {
    return this.post('/static-page/new', create, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  getAllFeatureSwitches(): AxiosPromise<Array<unknown>> {
    return this.get('/feature-switch/', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  getFeatureSwitch(
    switchName: string
  ): AxiosPromise<{ key: string; value: unknown }> {
    return this.get(`/feature-switch/${switchName}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  setFeatureSwitch(switchName: string, payload: unknown): AxiosPromise<void> {
    return this.post(`/feature-switch/${switchName}`, payload, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  deleteFeatureSwitch(switchName: string): AxiosPromise<void> {
    return this.delete(`/feature-switch/${switchName}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getTranslations(): AxiosPromise<TranslationsDto> {
    return this.get('/translations', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  saveTranslationsApp(payload: any): AxiosPromise<string> {
    return this.post('/translations/app', payload, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  deleteTranslationsApp(): AxiosPromise<void> {
    return this.delete('/translations/app', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  translationsAppOn(): AxiosPromise<string> {
    return this.post(
      '/translations/app/on',
      {},
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }
  translationsAppOff(): AxiosPromise<string> {
    return this.post(
      '/translations/app/off',
      {},
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }
  getTranslationsBackoffice(): AxiosPromise<TranslationsResponse> {
    return this.get('/translations/backoffice', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  saveTranslationsBackoffice(payload: string): AxiosPromise<string> {
    return this.post('/translations/backoffice', payload, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  deleteTranslationsBackoffice(): AxiosPromise<void> {
    return this.delete('/translations/backoffice', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  translationsBackofficeOn(): AxiosPromise<string> {
    return this.post(
      '/translations/backoffice/on',
      {},
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }
  translationsBackofficeOff(): AxiosPromise<string> {
    return this.post(
      '/translations/backoffice/off',
      {},
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  getDashboard(id: string): AxiosPromise<DashboardDto> {
    return this.get(`/dashboard/by-id/${id}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getAllDashboards({
    pageNumber,
    pageSize
  }: Pagination.Page): AxiosPromise<Pagination.Pager<SimpleDashboardDto>> {
    return this.get('/dashboard/list', {
      params: pickBy({
        pageNumber,
        pageSize
      }),
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
}
