import { ApiServiceWithSettings, Audience, Authentication } from '../../common'
import { AxiosPromise } from 'axios'
import Relations from '@one/types'
type RelatedDocumentResults = Relations.Relations.RelatedDocumentResults
type RelatedDocument = Relations.Relations.RelatedDocument

export class BackofficeRelationsApi extends ApiServiceWithSettings {
  getRelatedDocuments(
    documentId: string,
    documentType: string
  ): AxiosPromise<Array<RelatedDocument>> {
    return this.get('/relations/related-documents', {
      params: {
        documentId: documentId,
        documentType: documentType
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getRelatedDocumentsByIds(
    documents: Array<{ id: string; type: string }>
  ): AxiosPromise<RelatedDocumentResults> {
    return this.post(
      '/relations/related-documents',
      { documents },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  getRelatedDocumentsSearch(
    query: string
  ): AxiosPromise<Relations.Relations.QueryResult> {
    return this.get('/relations/related-documents-search', {
      params: { query },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
}
