import { ApiNamespaces, IQueuedRequest, OneAPIOptions } from '../../common'
import { BackofficeRelationsApi } from './backoffice'
import { Queue } from '../../queue'

export class Relations implements ApiNamespaces {
  backoffice: BackofficeRelationsApi
  constructor(
    name: string,
    sessionId: string,
    requestsQueue: Queue<IQueuedRequest>,
    options?: OneAPIOptions
  ) {
    this.backoffice = new BackofficeRelationsApi(
      name,
      sessionId,
      requestsQueue,
      options
    )
  }

  invalidateAll() {
    this.backoffice.invalidateAll()
  }
}
